<template>
  <MainFarmCard
    :imageSrc="farmImage"
    :title="title"
    :fields="farmFields"
    :address="address"
  >
    <!-- Operations for FarmItem -->
    <template #actions>
      <CDropdown placement="bottom-end" direction="dropup">
        <template #toggler-content>عملیات</template>
        <CDropdownItem v-if="canEdit" @click="editFarm">
          <i class="fa fa-pencil"></i>
          <span>ویرایش</span>
        </CDropdownItem>
        <CDropdownItem v-if="canDelete" @click="deleteFarm">
          <i class="fa fa-trash"></i>
          <span>حذف</span>
        </CDropdownItem>
        <CDropdownItem class="y-center-g-5" @click="viewDietPlan">
          <i class="fa fa-cutlery"></i>
          <span>برنامه غذایی</span>
        </CDropdownItem>
        <CDropdownItem class="y-center-g-5" @click="viewAdvice">
          <i class="fa fa-graduation-cap"></i>
          <span>توصیه های زمین</span>
        </CDropdownItem>
      </CDropdown>
      <CButton class="flex-grow-1" color="primary" style="padding: 3px 18px;" @click="viewDetails">
        جزئیات
      </CButton>
    </template>
  </MainFarmCard>
</template>

<script>
import MainFarmCard from "../../components/MainFarmCard.vue";
import { apiUrlRoot } from "../../constants/config";
import { mapActions } from "vuex";

export default {
  name: "farmItem",
  components: {
    MainFarmCard,
  },
  props: {
    itemModel: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return this.itemModel.title;
    },
    farmFields() {
      return [
        { label: "نوع محصول", value: this.itemModel.productType },
        { label: "مساحت", value: `${this.itemModel.farmArea} هکتار` },
        { label: "نوع کشت", value: this.itemModel.cultivationsType },
      ];
    },
    address() {
      return this.itemModel.address;
    },
    farmImage() {
      // Use the provided image if it exists, otherwise fallback to a default
      return this.itemModel.productImage
        ? `${apiUrlRoot}ProductImage/${this.itemModel.productImage}`
        : "https://picsum.photos/200";
    },
    canEdit() {
      return this.itemModel.canEdit;
    },
    canDelete() {
      return this.itemModel.canDelete;
    },
  },
  methods: {
    ...mapActions("dashboard", ["GetFarmDietPlanByFarmId", "GetFarmAdviceByFarmId"]),

    editFarm() {
      this.$emit("editFarm", this.itemModel.id);
    },
    deleteFarm() {
      this.$emit("deleteFarm", this.itemModel.id);
    },
    async viewDietPlan() {
      const result = await this.GetFarmDietPlanByFarmId({ farmId: this.itemModel.farmId });
      if (result.succeeded && result.data.length > 0) {
        this.$router.push({
          name: "برنامه غذایی زمین",
          params: { dietImage: result.data[0].image },
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `برنامه غذایی برای این زمین یافت نشد`,
          type: "error",
        });
      }
    },
    async viewAdvice() {
      const result = await this.GetFarmAdviceByFarmId({ farmId: this.itemModel.farmId });
      if (result.succeeded && result.data.length > 0) {
        this.$router.push({
          name: "توصیه های زمین",
          params: { adviceId: result.data[0].id },
        });
      } else {
        this.$notify({
          title: "پیام سیستم",
          text: `توصیه ای برای این زمین یافت نشد`,
          type: "error",
        });
      }
    },
    viewDetails() {
      this.$emit("viewDetails", this.itemModel.farmId);
    },
  },
};
</script>

<style scoped>
/* Custom styles for farmItem */
</style>
